import { ZxcvbnResult } from '@zxcvbn-ts/core';
import { atom, selector } from 'recoil';

import { getUserDetailsByIp } from '@/lib/api';
import { DISTANCE_UNITS, NOTIFICATION_TYPE } from '@/lib/constants';
import { LockDialogContentInterface, UserDetails, UserSettingsInterface } from '@/types';

export const DEFAULT_USER_SETTINGS: UserSettingsInterface = {
  distanceUnits: {
    value: DISTANCE_UNITS.KM,
  },
  shipmentViewSorting: {
    value: [],
  },
  notifications: {
    value: {
      [NOTIFICATION_TYPE.SYSTEM]: { channels: { email: true } },
      [NOTIFICATION_TYPE.INVITE]: { channels: { email: true } },
      [NOTIFICATION_TYPE.TRACKING]: { channels: { email: true } },
    },
  },
};

export const DEFAULT_DIALOG_CONTENT: LockDialogContentInterface = {
  title: '',
  description: '',
};

/**
 * The user settings state
 * Not to be used outside of other Recoil state, use the
 * useUserSettings hook to read and update the user settings
 */
export const userSettingsState = atom({
  key: 'userSettingsState',
  default: DEFAULT_USER_SETTINGS,
  dangerouslyAllowMutability: true,
});

export const userDetailsState = selector<UserDetails | null>({
  key: 'userDetailsState',
  get: async () => {
    try {
      const response = await getUserDetailsByIp();
      return response;
    } catch (e) {
      return null;
    }
  },
});

// The container ref for the the dialog portal
export const dialogPortalState = atom<HTMLElement | HTMLDivElement | null>({
  key: 'dialogPortalState',
  default: null,
});

export const lockedDialogIsOpenState = atom<boolean>({
  key: 'lockedDialogIsOpenState',
  default: false,
});

export const lockedDialogContentState = atom<LockDialogContentInterface>({
  key: 'lockedDialogContentState',
  default: DEFAULT_DIALOG_CONTENT,
});

export const passwordStrengthState = atom<ZxcvbnResult | null>({
  key: 'passwordStrengthState',
  default: null,
});
